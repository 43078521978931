<template>
  <div class="section7">
    <div class="title">后台运营</div>
    <img class="img1" src="./imgs/section7-01.png" />
    <img class="img2" src="./imgs/section7-02.png" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section7 {
  position: relative;
  background: #fff;
  height: 5.4rem;
  padding: 1px 0;
  background: url(./imgs/section7-bg.png) no-repeat;
  .title {
    margin-top: 0.52rem;
    margin-left: 7.845rem;
    font-size: 0.31rem;
    color: #242424;
  }
  img {
    position: absolute;
    width: 4.23rem;
    height: 2.39rem;
    top: 1.73rem;
  }
  .img1 {
    left: 0.44rem;
  }
  .img2 {
    left: 4.93rem;
  }
}
</style>
