<template>
  <div class="section3">
    <div class="title">因为规范，所以我们很高效</div>
    <div class="steps">
      <div class="line" :style="{ left: `${currentIndex * 1.52}rem` }"></div>
      <div
        class="step"
        :class="{ 'step-active': currentIndex == 0 }"
        @click="go(0)"
      >
        商务流程规范
      </div>
      <div
        class="step"
        :class="{ 'step-active': currentIndex == 1 }"
        @click="go(1)"
      >
        研发流程规范
      </div>
      <div
        class="step"
        :class="{ 'step-active': currentIndex == 2 }"
        @click="go(2)"
      >
        售后流程规范
      </div>
    </div>
    <div class="items">
      <div class="item">
        <img src="./imgs/section3-icon1.png" />
        <div class="name">流程</div>
      </div>
      <div class="item">
        <img src="./imgs/section3-icon2.png" />
        <div class="name">参与角色</div>
      </div>
      <div class="item">
        <img src="./imgs/section3-icon3.png" />
        <div class="name">交付物</div>
        <div class="desc">（给您的东东）</div>
      </div>
    </div>
    <div class="swiper">
      <transition name="fade">
        <div class="item" v-if="currentIndex == 0">
          <img src="./imgs/section3-item1.png" />
        </div>
      </transition>
      <transition name="fade">
        <div class="item" v-if="currentIndex == 1">
          <img src="./imgs/section3-item2.png" />
        </div>
      </transition>
      <transition name="fade">
        <div class="item" v-if="currentIndex == 2">
          <img src="./imgs/section3-item3.png" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0
    };
  },
  watch: {
    currentIndex() {
      if (this._ind) {
        clearTimeout(this._ind);
        this._ind = setTimeout(this.next, 5000);
      }
    }
  },
  methods: {
    next() {
      this.currentIndex++;
      if (this.currentIndex > 2) {
        this.currentIndex = 0;
      }
    },
    prev() {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = 2;
      }
    },
    go(ind) {
      if (this._ind) {
        clearTimeout(this._ind);
      }
      this.currentIndex = ind;
      this._ind = setTimeout(this.next, 5000);
    }
  },
  created() {
    this._ind = setTimeout(this.next, 5000);
  },
  beforeDestroy() {
    if (this._ind) {
      clearTimeout(this._ind);
    }
  }
};
</script>

<style lang="scss" scoped>
.section3 {
  position: relative;
  height: 5.4rem;
  background: url(./imgs/section3-bg.png) no-repeat;
  background-size: 100% 100%;
  padding: 1px 0;
  .title {
    margin-top: 0.33rem;
    text-align: center;
    font-size: 0.29rem;
    line-height: 0.29rem;
  }
  .steps {
    position: relative;
    width: 4rem;
    margin: 0 auto;
    margin-top: 0.39rem;
    text-align: center;
    line-height: 0.15rem;
    .line {
      position: absolute;
      bottom: -0.05rem;
      left: 0;
      border-bottom: 2px solid #fff;
      width: 1rem;
      transition: all 0.3s;
    }
    .step {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      font-size: 0.15rem;
      color: #ccc;
      width: 0.9rem;
      text-align: center;
      margin-right: 0.63rem;
      transition: all 0.3s;
      cursor: pointer;
      &::after {
        content: " ";
        position: absolute;
        top: 0.02rem;
        right: -0.3rem;
        display: block;
        height: 0.08rem;
        width: 0.08rem;
        border-right: 1px solid #666;
        border-bottom: 1px solid #666;
        transform: rotate(-45deg);
      }
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }
    }
    .step-active {
      color: #fff;
    }
  }
  .items {
    position: absolute;
    top: 1.7rem;
    left: 0.5rem;
    width: 0.8rem;
    height: 3rem;
    .item {
      margin-bottom: 0.7rem;
      img {
        display: block;
        height: 0.2rem;
        width: 0.2rem;
        margin: 0 auto;
      }
      .name {
        font-size: 0.13rem;
        text-align: center;
        color: #fff;
      }
      .desc {
        font-size: 0.11rem;
        color: #dcdcdc;
        text-align: center;
      }
    }
  }
  .swiper {
    position: relative;
    margin: 0.5rem auto;
    height: 3rem;
    width: 7rem;
    .fade-enter-active,
    .fade-leave-active {
      transition: all 0.5s;
    }
    .fade-enter {
      opacity: 0;
      transform: translate(-100%, 0);
    }
    .fade-leave-to {
      opacity: 0;
      transform: translate(100%, 0);
    }
    .item {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      transition: all 0.5s;
      img {
        display: block;
        height: 100%;
        margin: 0 auto;
      }
    }
  }
}
</style>
