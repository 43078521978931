<template>
  <div class="section6">
    <div class="title">电商、平台、微信、小程序、以及各类定制需求……</div>
    <div class="title2">就不一一展开了，就是这么任（jiao）性（ao）</div>
    <div class="title3">免费试用案例</div>
    <div class="steps">
      <div class="step">联系我们</div>
      <img class="arrow" src="./imgs/section6-arrow.png" />
      <div class="step">说明需求</div>
      <img class="arrow" src="./imgs/section6-arrow.png" />
      <div class="step">获取APP<br />下载地址</div>
      <img class="arrow" src="./imgs/section6-arrow.png" />
      <div class="step">试用体验</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section6 {
  position: relative;
  height: 4.8rem;
  background: url(./imgs/section6-bg.png) no-repeat;
  background-size: 100% 100%;
  padding: 1px 0;
  .title {
    margin-top: 0.48rem;
    margin-left: 0.76rem;
    font-size: 0.18rem;
    color: #fff;
  }
  .title2 {
    margin-top: 0.375rem;
    margin-left: 3.63rem;
    font-size: 0.18rem;
    color: #fff;
  }
  .title3 {
    margin-top: 1.19rem;
    text-align: center;
    font-size: 0.21rem;
    font-weight: bold;
    color: #fff;
  }
  .steps {
    margin-top: 0.87rem;
    text-align: center;
    .step {
      display: inline-block;
      vertical-align: middle;
      font-size: 0.18rem;
      margin: 0 0.5rem;
    }
    .arrow {
      display: inline-block;
      vertical-align: middle;
      width: 0.22rem;
      height: 0.05rem;
    }
  }
}
</style>
