<template>
  <div class="section9" @mousemove="currentIndex = 0">
    <div class="cover" @mousemove.stop></div>
    <div
      class="img8"
      :class="{ 'img-move': currentIndex > 7 }"
      @mouseenter="currentIndex = 7"
      @mousemove.stop
    >
      <img src="./imgs/section9-8.png" />
    </div>
    <div
      class="img7"
      :class="{ 'img-move': currentIndex > 6 }"
      @mouseenter="currentIndex = 6"
      @mousemove.stop
    >
      <img src="./imgs/section9-7.png" />
    </div>
    <div
      class="img6"
      :class="{ 'img-move': currentIndex > 5 }"
      @mouseenter="currentIndex = 5"
      @mousemove.stop
    >
      <img src="./imgs/section9-6.png" />
    </div>
    <div
      class="img5"
      :class="{ 'img-move': currentIndex > 4 }"
      @mouseenter="currentIndex = 4"
      @mousemove.stop
    >
      <img src="./imgs/section9-5.png" />
    </div>
    <div
      class="img4"
      :class="{ 'img-move': currentIndex > 3 }"
      @mouseenter="currentIndex = 3"
      @mousemove.stop
    >
      <img src="./imgs/section9-4.png" />
    </div>
    <div
      class="img3"
      :class="{ 'img-move': currentIndex > 2 }"
      @mouseenter="currentIndex = 2"
      @mousemove.stop
    >
      <img src="./imgs/section9-3.png" />
    </div>
    <div
      class="img2"
      :class="{ 'img-move': currentIndex > 1 }"
      @mouseenter="currentIndex = 1"
      @mousemove.stop
    >
      <img src="./imgs/section9-2.png" />
    </div>
    <div
      class="img1"
      :class="{ 'img-move': currentIndex > 0 }"
      @mouseenter="currentIndex = 0"
      @mousemove.stop
    >
      <img src="./imgs/section9-1.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0
    };
  }
};
</script>

<style lang="scss" scoped>
.section9 {
  position: relative;
  background: #fff;
  height: 5.4rem;
  padding: 1px 0;
  background: url(./imgs/section9-bg.png) no-repeat;
  .cover {
    position: absolute;
    top: 1.28rem;
    left: 1.67rem;
    right: 1.67rem;
    bottom: 0.46rem;
  }
  img,
  div {
    position: absolute;
    transition: all 0.5s;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    &:hover {
      cursor: pointer;
      transform: scale(1.2, 1.2);
    }
  }
  .img-move {
    transform: translate(100%, 0);
  }
  .img1 {
    top: 1.105rem;
    left: 6.12rem;
    width: 1.69rem;
    height: 3.66rem;
  }
  .img2 {
    top: 1.27rem;
    left: 5.42rem;
    width: 1.68rem;
    height: 3.38rem;
  }
  .img3 {
    top: 1.4rem;
    left: 4.78rem;
    width: 1.53rem;
    height: 3.24rem;
  }
  .img4 {
    top: 1.4rem;
    left: 4.19rem;
    width: 1.52rem;
    height: 3.25rem;
  }
  .img5 {
    top: 1.41rem;
    left: 3.57rem;
    width: 1.82rem;
    height: 3.22rem;
  }
  .img6 {
    top: 1.4rem;
    left: 2.96rem;
    width: 1.82rem;
    height: 3.24rem;
  }
  .img7 {
    top: 1.42rem;
    left: 2.35rem;
    width: 1.82rem;
    height: 3.22rem;
  }
  .img8 {
    top: 1.42rem;
    left: 1.75rem;
    width: 1.82rem;
    height: 3.22rem;
  }
}
</style>
