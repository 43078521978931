<template>
  <div class="footer">
    <div></div>
    <div>
      <img src="../assets/brand.png" />
      杭州挚程科技有限公司
    </div>
    <div style="cursor: pointer;" @click="goMap">
      <img src="../assets/lbs.png" />
      杭州爱力中心B座2层
    </div>
    <div style="cursor: pointer;" @click="goBeian">
      <img src="../assets/beian.png" />
      浙公网安备 33010802010555号
    </div>
    <div style="cursor: pointer;" @click="goICP">
      <img src="../assets/icp.png" />
      浙ICP备19045839号-1
    </div>
    <div>
      <img src="../assets/copy.png" />
      Copyright©2019-2029 zhichenghz.com 版权所有
    </div>
    <div>
      <img src="../assets/tel.png" />
      联系方式: +86 139 5818 5422
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goMap() {
      window.open("https://j.map.baidu.com/73/pDoi");
    },
    goBeian() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802010555"
      );
    },
    goICP() {
      window.open("http://beian.miit.gov.cn");
    }
  }
};
</script>

<style lang="scss">
.footer {
  position: relative;
  height: 0.33rem;
  line-height: 0.33rem;
  color: #e5e5e5;
  font-size: 0.08rem;
  padding: 0 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #101010;
  img {
    width: 0.1rem;
    height: 0.1rem;
    margin-right: 0.03rem;
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}
</style>
