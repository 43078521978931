<template>
  <div class="section1">
    <div @click="goAsk" class="btn">咨询报价</div>
    <img src="./imgs/section1-bg.png" />
  </div>
</template>

<script>
export default {
  methods: {
    goAsk() {
      window.open(
        // "https://affim.baidu.com/unique_46250393/chat?siteId=18921975&userId=46250393&siteToken=016057f948536c7916352fbab244ef24"
        "https://affim.baidu.com/unique_46250393/chat?siteId=18921975&userId=46250393&siteToken=016057f948536c7916352fbab244ef24"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  background: #fff;
  img {
    width: 100%;
  }
  .btn {
    // margin: 0.3rem 4rem;
    width: 1.5rem;
    height: 0.3rem;
    font-size: 0.12rem;
    background: #0068fa;
    border-radius: 4px;
    line-height: 0.3rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    left: 2.5rem;
    top: 3.5rem;
  }
}
</style>
