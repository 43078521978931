<template>
  <div class="section2">
    <div class="title">IM即时通讯</div>
    <div class="title2">应用场景</div>
    <div class="scenes">
      <div
        @click="sel(0)"
        class="scene"
        :class="{ 'scene-active': currentIndex == 0 }"
      >
        <div class="icons">
          <img class="img" src="./imgs/section2-icon1.png" />
        </div>
        <div class="name">多人语音聊天室</div>
      </div>
      <div
        @click="sel(1)"
        class="scene"
        :class="{ 'scene-active': currentIndex == 1 }"
      >
        <div class="icons">
          <img class="img" src="./imgs/section2-icon2.png" />
        </div>
        <div class="name">企业客服/售后</div>
      </div>
      <div
        @click="sel(2)"
        class="scene"
        :class="{ 'scene-active': currentIndex == 2 }"
      >
        <div class="icons">
          <img class="img" src="./imgs/section2-icon3.png" />
        </div>
        <div class="name">OA/CRM/ERP沟通桥梁</div>
      </div>
      <div
        @click="sel(3)"
        class="scene"
        :class="{ 'scene-active': currentIndex == 3 }"
      >
        <div class="icons">
          <img class="img" src="./imgs/section2-icon4.png" />
        </div>
        <div class="name">陌生人社交</div>
      </div>
      <div
        @click="sel(4)"
        class="scene"
        :class="{ 'scene-active': currentIndex == 4 }"
      >
        <div class="icons">
          <img class="img" src="./imgs/section2-icon5.png" />
        </div>
        <div class="name">游戏交流</div>
      </div>
    </div>
    <div class="swiper">
      <div class="arrow-left" @click="prev"></div>
      <div class="arrow-right" @click="next"></div>
      <transition name="fade">
        <div class="item" v-if="currentIndex == 0">
          <img
            class="img"
            src="./imgs/solution-liaotian.png"
            style="width:auto;"
          />
          <div class="text">
            <div class="s1">多人语音聊天室</div>
            <div class="s2">
              在线KTV、连麦开黑、多人相亲、歌曲接龙
            </div>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="item" v-if="currentIndex == 1">
          <img
            class="img"
            src="./imgs/solution-kefu.png"
            style="width:3.5rem;height:2rem;margin-top:.4rem;"
          />
          <div class="text">
            <div class="s1">企业客服/售后</div>
            <div class="s2">倾听客户、了解客户、启发客户、引导客户</div>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="item" v-if="currentIndex == 2">
          <img class="img" src="./imgs/solution-oa.png" style="width:auto;" />
          <div class="text">
            <div class="s1">OA/CRM/ERP沟通桥梁</div>
            <div class="s2">全过程数字化、智能化， 提升客户满意度</div>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="item" v-if="currentIndex == 3">
          <img
            class="img"
            src="./imgs/solution-moshengren.png"
            style="width:auto;"
          />
          <div class="text">
            <div class="s1">陌生人社交</div>
            <div class="s2">有缘找到你的灵魂伴侣</div>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="item" v-if="currentIndex == 4">
          <img
            class="img"
            src="./imgs/solution-youxi.png"
            style="width:3.5rem;height:2rem;margin-top:.4rem;"
          />
          <div class="text">
            <div class="s1">游戏交流</div>
            <div class="s2">时实交流游戏战术、增加游戏趣味</div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0
    };
  },
  watch: {
    currentIndex() {
      if (this._ind) {
        clearTimeout(this._ind);
        this._ind = setTimeout(this.next, 5000);
      }
    }
  },
  methods: {
    next() {
      this.currentIndex++;
      if (this.currentIndex > 4) {
        this.currentIndex = 0;
      }
    },
    prev() {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = 4;
      }
    },
    sel(index) {
      this.currentIndex = index;
    }
  },
  created() {
    this._ind = setTimeout(this.next, 5000);
  },
  beforeDestroy() {
    if (this._ind) {
      clearTimeout(this._ind);
    }
  }
};
</script>

<style lang="scss" scoped>
.section2 {
  position: relative;
  height: 5.95rem;
  background: #0f1112;
  padding: 1px 0;
  .title {
    margin-top: 0.245rem;
    margin-left: 0.55rem;
    font-size: 0.36rem;
    line-height: 0.36rem;
    color: #fff;
  }
  .title2 {
    margin-top: 0.46rem;
    text-align: center;
    font-size: 0.21rem;
    line-height: 0.21rem;
    color: #fff;
  }
  .scenes {
    margin-top: 0.325rem;
    text-align: center;
    .scene {
      display: inline-block;
      vertical-align: top;
      margin-right: 0.5rem;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
    .icons {
      .img {
        display: inline-block;
        vertical-align: top;
        height: 0.3rem;
        width: 0.3rem;
        opacity: 0.5;
        transition: all 0.2s;
      }
    }
    .name {
      text-align: center;
      font-size: 0.09rem;
      line-height: 0.09rem;
      color: #909090;
      margin-top: 0.075rem;
      transition: all 0.2s;
    }
    .scene-active {
      .img {
        opacity: 1;
      }
      .name {
        color: #fff;
      }
    }
  }
  .swiper {
    position: relative;
    margin: 0.5rem auto;
    width: 7rem;
    height: 3rem;
    .arrow-left {
      position: absolute;
      top: 50%;
      left: -0.1rem;
      margin-top: -0.05rem;
      height: 0.1rem;
      width: 0.1rem;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: rotate(45deg);
      cursor: pointer;
    }
    .arrow-right {
      position: absolute;
      top: 50%;
      right: -0.1rem;
      margin-top: -0.05rem;
      height: 0.1rem;
      width: 0.1rem;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);
      cursor: pointer;
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: all 0.5s;
    }
    .fade-enter {
      opacity: 0;
      transform: translate(-100%, 0);
    }
    .fade-leave-to {
      opacity: 0;
      transform: translate(100%, 0);
    }
    .item {
      position: absolute;
      top: 0;
      left: 0.1rem;
      height: 3rem;
      width: 6.8rem;
      text-align: center;
      .img {
        display: inline-block;
        vertical-align: middle;
        width: 1.57rem;
        height: 3.1rem;
      }
      .text {
        display: inline-block;
        vertical-align: top;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        text-align: left;
        .s1 {
          font-size: 0.18rem;
          color: #fff;
        }
        .s2 {
          margin-top: 0.3rem;
          font-size: 0.1rem;
          color: #b9b9b9;
        }
      }
    }
  }
}
</style>
