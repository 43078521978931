import { render, staticRenderFns } from "./Corner.vue?vue&type=template&id=3eb1d205&scoped=true"
var script = {}
import style0 from "./Corner.vue?vue&type=style&index=0&id=3eb1d205&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eb1d205",
  null
  
)

export default component.exports