<template>
  <div class="section4">
    <div class="title">电商平台（跨境）</div>
    <img class="img1" src="./imgs/section4-01.png" />
    <img class="img2" src="./imgs/section4-02.png" />
    <img class="img3" src="./imgs/section4-03.png" />
    <img class="img4" src="./imgs/section4-04.png" />
    <img class="img5" src="./imgs/section4-05.png" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section4 {
  position: relative;
  background: url(./imgs/section2-bg.png) no-repeat;
  background-size: 100% 100%;
  height: 5.4rem;
  padding: 1px 0;
  .title {
    margin-top: 0.59rem;
    margin-left: 0.63rem;
    font-size: 0.31rem;
    color: #fff;
  }
  // 要就留着不要删掉
  img {
    transition: all 0.3s;
    &:hover {
      cursor: pointer;
      transform: scale(1.2, 1.2);
    }
  }
  .img1,
  .img2,
  .img3,
  .img4,
  .img5 {
    position: absolute;
    width: 1.44rem;
    height: 2.965rem;
    top: 1.545rem;
  }
  .img1 {
    left: 0.59rem;
  }
  .img2 {
    left: 2.24rem;
  }
  .img3 {
    left: 3.885rem;
  }
  .img4 {
    left: 5.53rem;
  }
  .img5 {
    top: 2.27rem;
    left: 7.285rem;
    width: 2.05rem;
    height: 1.45rem;
  }
}
</style>
