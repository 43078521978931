<template>
  <div class="slogan">
    <Header :absolute="true"></Header>
    <div class="arrows">
      <img
        class="arrow-item arrow-item-prev"
        src="./imgs/arrow.png"
        @click="prev"
      />
      <img class="arrow-item" src="./imgs/arrow.png" @click="next" />
    </div>
    <div class="swipper">
      <transition name="fade">
        <div class="swipper-item swipper-item1" v-if="currentIndex == 0">
          <div class="title">
            谁都“消费”得起的APP定制开发
          </div>
          <div class="items">
            <div class="item">APP</div>
            <div class="item">微信公众号</div>
            <div class="item">小程序</div>
            <div class="item">平台网站</div>
            <div class="item">聊天直播</div>
            <div class="item">金融</div>
            <div class="item">电商</div>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="swipper-item swipper-item2" v-if="currentIndex == 1">
          <div
            style="font-size: 0.34rem;line-height:.34rem;text-align: left;margin-top: 1.62rem;margin-left:1.36rem;"
          >
            聊天、直播、云课堂、电商
          </div>
          <div
            style="font-size: 0.34rem;line-height:.34rem;text-align: left;margin-top: .07rem;margin-left:4.4rem;"
          >
            统统是我们的“强项”
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="swipper-item swipper-item3" v-if="currentIndex == 2">
          <div
            style="font-size: 0.34rem;line-height:.34rem;text-align: left;margin-top: 1.62rem;margin-left:1.28rem;"
          >
            因为规范，所以我们很高效。
          </div>
          <div
            style="font-size: 0.34rem;line-height:.34rem;text-align: left;margin-top: .13rem;margin-left:2.67rem;"
          >
            40个工作日，从无到有，诞生您的梦想
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Header from "../Header";

export default {
  components: {
    Header
  },
  data() {
    return {
      currentIndex: 0
    };
  },
  watch: {
    currentIndex() {
      if (this._ind) {
        clearTimeout(this._ind);
        this._ind = setTimeout(this.next, 5000);
      }
    }
  },
  methods: {
    next() {
      this.currentIndex++;
      if (this.currentIndex > 2) {
        this.currentIndex = 0;
      }
    },
    prev() {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = 2;
      }
    }
  },
  created() {
    this._ind = setTimeout(this.next, 5000);
  },
  beforeDestroy() {
    if (this._ind) {
      clearTimeout(this._ind);
    }
  }
};
</script>

<style lang="scss" scoped>
.slogan {
  position: relative;
  height: 5.4rem;
  .arrows {
    position: absolute;
    z-index: 2;
    left: 1.37rem;
    top: 3.94rem;
    .arrow-item {
      display: inline-block;
      vertical-align: middle;
      width: 0.16rem;
      margin-right: 0.1rem;
      opacity: 0.5;
      transition: all 0.2s;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
    .arrow-item-prev {
      transform: rotate(180deg);
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .swipper {
    position: relative;
    height: 5.4rem;
    .swipper-item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 5.4rem;
    }
    .swipper-item1 {
      background: url(./imgs/section1-bg1.png) no-repeat;
      background-size: 100% 100%;
    }
    .swipper-item2 {
      background: url(./imgs/section1-bg2.png) no-repeat;
      background-size: 100% 100%;
    }
    .swipper-item3 {
      background: url(./imgs/section1-bg3.png) no-repeat;
      background-size: 100% 100%;
    }
    .title {
      font-size: 0.34rem;
      text-align: center;
      margin-top: 1rem;
    }
    .items {
      font-size: 0;
      text-align: center;
      margin-top: 0.3rem;
      .item {
        display: inline-block;
        font-size: 0.15rem;
        line-height: 0.15rem;
        vertical-align: middle;
        margin-right: 0.1rem;
        padding-right: 0.1rem;
        border-right: 1px solid #fff;
        &:last-child {
          margin-right: 0;
          border: none;
        }
      }
    }
  }
}
</style>
