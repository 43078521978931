<template>
  <div class="section3">
    <div class="title">视频直播</div>
    <img class="img1" src="./imgs/section3-01.png" />
    <img class="img2" src="./imgs/section3-02.png" />
    <img class="img3" src="./imgs/section3-03.png" />
    <img class="img4" src="./imgs/section3-04.png" />
    <img class="img5" src="./imgs/section3-05.png" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section3 {
  position: relative;
  background: url(./imgs/section3-bg.png) no-repeat;
  background-size: 100% 100%;
  height: 5.4rem;
  padding: 1px 0;
  .title {
    margin-top: 0.44rem;
    margin-left: 7.795rem;
    color: #000;
    font-size: 0.31rem;
  }
  // 要就留着不要删掉
  img {
    transition: all 0.3s;
    &:hover {
      cursor: pointer;
      transform: scale(1.15, 1.15);
    }
  }
  .img1,
  .img2,
  .img3,
  .img4,
  .img5 {
    position: absolute;
    width: 1.5255rem;
    height: 2.735rem;
    top: 1.485rem;
  }
  .img1 {
    left: 0.765rem;
  }
  .img2 {
    left: 2.44rem;
  }
  .img3 {
    left: 4.089rem;
  }
  .img4 {
    left: 5.7295rem;
  }
  .img5 {
    left: 7.39rem;
  }
}
</style>
