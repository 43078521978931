<template>
  <div class="section5">
    <img src="./imgs/section5-bg.png" @click="goMap" />
  </div>
</template>

<script>
export default {
  methods: {
    goMap() {
      window.open("https://j.map.baidu.com/73/pDoi");
    }
  }
};
</script>

<style lang="scss" scoped>
.section5 {
  position: relative;
  height: 4.8rem;
  background-color: #fff;
}
img {
  position: relative;
  margin: 0 0.85rem;
  cursor: pointer;
  height: 4.35rem;
}
</style>
