<template>
  <div class="section1">
    <Header></Header>
  </div>
</template>

<script>
import Header from "../Header";

export default {
  components: {
    Header
  }
};
</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  height: 5.4rem;
  background: url(./imgs/section1-bg.png) no-repeat;
  background-size: 100% 100%;
}
</style>
