<template>
  <div class="section1">
    <div class="info">
      <span class="title">{{ infoData.title }}</span>
      <img :src="infoData.img" />
      <div class="content"><p v-html="infoData.content" /></div>
      <!-- <div>
        <span class="title">{{ infoData.title }}</span>
        <br /><br />
        <span class="subtitle">{{ infoData.subtitle }}</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import newsData1 from "../../assets/data/news1";
import newsData2 from "../../assets/data/news2";
import newsData3 from "../../assets/data/news3";
import newsData4 from "../../assets/data/news4";
import newsData5 from "../../assets/data/news5";
import newsData6 from "../../assets/data/news6";
import newsData7 from "../../assets/data/news7";
import newsData8 from "../../assets/data/news8";
import newsData9 from "../../assets/data/news9";
import newsData10 from "../../assets/data/news10";

export default {
  data() {
    return {
      infolist: [
        newsData1,
        newsData2,
        newsData3,
        newsData4,
        newsData5,
        newsData6,
        newsData7,
        newsData8,
        newsData9,
        newsData10
      ],
      id: this.$route.params.id,
      infoData: {}
    };
  },
  // 设置meta信息
  metaInfo() {
    return {
      title: this.infoData.title,
      meta: [
        { charset: "utf-8" },
        { content: "website", property: "og:type" },
        { content: this.infoData.subtitle, property: "og:description" },
        {
          content: "https://www.zhichenghz.com/infodetail/" + this.id,
          property: "og:url"
        },
        { content: this.infoData.title, property: "og:site_name" },
        { content: this.infoData.title, property: "og:title" },
        { content: this.infoData.img, property: "og:image:secure_url" },
        { content: this.infoData.img, property: "og:image" },
        { content: 800, property: "og:image:width" },
        { content: 300, property: "og:image:height" },
        { content: "image/jpeg", property: "og:image:type" },
        {
          content: "all|none|index|noindex|follow|nofollow",
          property: "Robots"
        },
        { content: this.infoData.author, property: "Author" },
        { content: this.infoData.keywords, property: "keywords" },

        { content: "summary_large_image", property: "twitter:card" },
        { content: this.infoData.title, property: "twitter:title" },
        { content: this.infoData.subtitle, property: "twitter:description" },
        { content: this.infoData.img, property: "twitter:image" },
        { content: "summary_large_image", property: "twitter:label1" }
        // { content: 'summary_large_image', property: "twitter:card"},
        // { content: 'summary_large_image', property: "twitter:card"},
        // { content: 'summary_large_image', property: "twitter:card"},
      ]
    };
  },
  methods: {
    sel() {},
    loadData() {
      this.infoData = this.infolist[this.id - 1];
    }
  },
  created() {
    this.loadData();
  }
};
</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  background: #fff;
  padding-bottom: 0.05rem;
  .info {
    margin: 0 1rem;
    color: #333;
    font-size: 0.1rem;
    display: grid;
    justify-content: center;
    text-align: center;
    .title {
      font-size: 0.3rem;
      color: #333;
      width: 5.5rem;
      text-align: center;
      margin-top: 0.1rem;
    }
    img {
      width: 5.5rem;
      // height: 1.2rem;
      object-fit: cover;
      border-radius: 0.06rem;
      margin-top: 0.2rem;
    }
    .content {
      margin-top: 0.2rem;
      width: 5.5rem;
      font-size: 0.1rem;
      text-align: left;
    }
    div {
      position: relative;
      margin-top: 0.25rem;
      margin-left: 0.2rem;
      .subtitle {
        font-size: 0.1rem;
        color: #999;
      }
    }
  }
}
</style>
