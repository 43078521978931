<template>
  <div class="section6">
    <div class="title">演示大厅</div>
    <img class="img1" src="./imgs/section6-01.png" />
    <img class="img2" src="./imgs/section6-02.png" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section6 {
  position: relative;
  background: #fff;
  height: 5.4rem;
  padding: 1px 0;
  background: url(./imgs/section6-bg.png) no-repeat;
  .title {
    margin-top: 0.65rem;
    margin-left: 0.625rem;
    font-size: 0.31rem;
    color: #fff;
  }
  img {
    position: absolute;
    width: 4.25rem;
    height: 2.36rem;
    top: 1.72rem;
  }
  .img1 {
    left: 0.49rem;
  }
  .img2 {
    left: 4.79rem;
  }
}
</style>
