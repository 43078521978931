<template>
  <div class="section2">
    <div class="title1">挚程</div>
    <div class="title2">诚挚为您服务！</div>
    <div class="year">2019</div>
    <div class="article1">
      <div class="title">关于挚程</div>
      <div class="txt">
        <span style="font-weight:bold;">杭州挚程科技有限公司</span>
        初创于2019年，是一家对移动客户端、WEB门户、互联网金融、信息服务平台、应用型软件等有着丰富的开发经验的技术创新型互联网企业。
      </div>
    </div>
    <div class="article2">
      <div class="title">关于团队</div>
      <div class="txt">
        <span style="font-weight:bold;">我们团队</span
        >汇集多名来自网易、电信、本地知名互联网企业的架构师、金融系统的高级软件工程师，其中核心研发人员工作经验都在10年以上，对移动互联网、高并发高安全性、互联网科技服务等有着丰富的开发经验及许多业界知名的成功案例。
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section2 {
  position: relative;
  height: 5.4rem;
  background: #fff;
  .title1 {
    position: absolute;
    top: 0.775rem;
    left: 1.23rem;
    font-size: 0.34rem;
    line-height: 0.34rem;
    color: #131313;
    &::before {
      content: " ";
      position: absolute;
      top: 0.12rem;
      left: -0.3rem;
      height: 0.1rem;
      width: 0.1rem;
      border: 2px solid #000;
      border-radius: 50%;
    }
  }
  .title2 {
    position: absolute;
    top: 1.32rem;
    left: 1.245rem;
    font-size: 0.34rem;
    color: #131313;
  }
  .year {
    position: absolute;
    top: 0.98rem;
    left: 6.78rem;
    font-size: 0.64rem;
    line-height: 0.64rem;
    color: #131313;
    padding-bottom: 0.09rem;
    padding-left: 0.03rem;
    padding-right: 0.03rem;
    border-bottom: 0.03rem solid #000;
    font-weight: bold;
  }
  .article1 {
    position: absolute;
    top: 2.32rem;
    left: 0.95rem;
    right: 0.95rem;
  }
  .article2 {
    position: absolute;
    top: 3.685rem;
    left: 0.95rem;
    right: 0.95rem;
  }
  .title {
    font-size: 0.18rem;
    color: #000;
    margin-bottom: 0.3rem;
  }
  .txt {
    font-size: 0.13rem;
    color: #000;
    line-height: 0.3rem;
  }
}
</style>
