<template>
  <div id="app">
    <router-view />
    <!-- <Service class="abs-service"></Service> -->
  </div>
</template>

<script>
// import Service from "./views/Service";

export default {
  components: {
    // Service
  }
};
</script>

<style lang="scss">
.abs-service {
  position: fixed;
  bottom: 0.2rem;
  right: 0.2rem;
  background: #333;
}
body,
html {
  margin: 0;
  background: #000;
}
@font-face {
  font-family: "PingFangSC";
  src: url(./assets/PingFangSCRegular.ttf) format("truetype");
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.3rem;
  color: #fff;
  font-family: "PingFangSC", sans-serif;
}
.content {
  display: block;
  margin: 0 auto;
  width: 1200px;
}
</style>
