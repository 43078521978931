<template>
  <div class="section1">
    <div class="title">Success Case</div>
    <div class="types">
      <div class="type">APP</div>
      <div class="type">微信小程序</div>
      <div class="type">PC</div>
      <div class="type">后台网站</div>
    </div>
    <div class="line"></div>
    <img class="brand" src="./imgs/section1.png" />
    <div class="slogan">选择挚程，开发稳定的产品</div>
    <div class="msg1">Eternally grateful heart,</div>
    <div class="msg2">heart can more walk more wide.</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  height: 4.04rem;
  background: #fff;
  .title {
    position: absolute;
    top: 1.33rem;
    left: 1.17rem;
    font-size: 0.3rem;
    padding: 0.16rem;
    padding-right: 0.47rem;
    border-bottom: 1px solid #000;
    color: #000;
  }
  .types {
    position: absolute;
    top: 2.37rem;
    left: 1.17rem;
    .type {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.5rem;
      color: #000;
      font-size: 0.08rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &::before {
        content: " ";
        display: inline-block;
        vertical-align: middle;
        line-height: 0.1rem;
        height: 0.05rem;
        width: 0.05rem;
        border-radius: 50%;
        border: 1px solid #000;
        margin-right: 0.02rem;
      }
    }
  }
  .line {
    position: absolute;
    top: 1.08rem;
    left: 5.36rem;
    height: 1.33rem;
    border-left: 1px solid #2c2c2c;
  }
  .brand {
    position: absolute;
    top: 0.5rem;
    left: 5.84rem;
    height: 1.3rem;
    width: 1.5rem;
  }
  .slogan {
    position: absolute;
    top: 0.685rem;
    left: 7.67rem;
    font-size: 0.12rem;
    color: #101213;
  }
  .msg1 {
    position: absolute;
    top: 2.11rem;
    left: 5.45rem;
    font-size: 0.08rem;
    color: #333333;
  }
  .msg2 {
    position: absolute;
    top: 2.35rem;
    left: 6.01rem;
    font-size: 0.08rem;
    color: #333333;
  }
}
</style>
