<template>
  <div class="section1">
    <div @click="goAsk" class="btn"></div>
    <div @click="goAsk" class="btn btn2"></div>
    <img src="./imgs/section3-bg.png" />
    <img src="./imgs/section4-bg.png" />
    <img src="./imgs/section5-bg.png" />
    <img src="./imgs/section6-bg.png" />
    <img src="./imgs/section7-bg.png" />
    <img src="./imgs/section8-bg.png" />
  </div>
</template>

<script>
export default {
  methods: {
    goAsk() {
      let pathInfo = this.$router.resolve({ path: "/prodaigptsession" });
      window.open(pathInfo.href, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  background: #000;
  img {
    width: 100%;
  }
  .btn {
    // margin: 0.3rem 4rem;
    width: 2.86rem;
    height: 0.5rem;
    left: 35%;
    top: 23rem;
    font-size: 0.12rem;
    background: url("./imgs/section2-btn.png");
    background-size: 100% 100%;
    border-radius: 4px;
    line-height: 0.3rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
  }
  .btn2 {
    top: 3.5rem;
  }
}
</style>
