<template>
  <div class="section2">
    <div class="title">虽然“不贵”，但我们只出精品。</div>
    <div class="swipper">
      <transition name="fade">
        <div class="swipper-item" v-if="currentIndex == 0">
          <img class="img" src="./imgs/section2-item1.png" />
          <img class="img" src="./imgs/section2-item3.png" />
          <img class="img" src="./imgs/section2-item2.png" />
        </div>
      </transition>
      <transition name="fade">
        <div class="swipper-item" v-if="currentIndex == 1">
          <img class="img" src="./imgs/section2-item21.png" />
          <img class="img" src="./imgs/section2-item22.png" />
          <img class="img" src="./imgs/section2-item23.png" />
        </div>
      </transition>
      <transition name="fade">
        <div class="swipper-item" v-if="currentIndex == 2">
          <img class="img3" src="./imgs/section2-item31.png" />
          <img class="img3" src="./imgs/section2-item32.png" />
          <img class="img3" src="./imgs/section2-item33.png" />
        </div>
      </transition>
      <div class="menu">
        <div class="menu-group">
          <div
            class="item"
            :class="{ 'item-active': currentIndex == 0 }"
            @click="currentIndex = 0"
          >
            APP案例
          </div>
          <div
            class="item"
            :class="{ 'item-active': currentIndex == 1 }"
            @click="currentIndex = 1"
          >
            微信案例
          </div>
          <div
            class="item"
            :class="{ 'item-active': currentIndex == 2 }"
            @click="currentIndex = 2"
          >
            网站案例
          </div>
        </div>
        <div class="menu-group">
          <div class="item" @click="go('/case')">更多案例</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0
    };
  },
  watch: {
    currentIndex() {
      if (this._ind) {
        clearTimeout(this._ind);
        this._ind = setTimeout(this.next, 5000);
      }
    }
  },
  methods: {
    next() {
      this.currentIndex++;
      if (this.currentIndex > 2) {
        this.currentIndex = 0;
      }
    },
    prev() {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = 2;
      }
    },
    go(path) {
      this.$router.push(path);
    }
  },
  created() {
    this._ind = setTimeout(this.next, 5000);
  },
  beforeDestroy() {
    if (this._ind) {
      clearTimeout(this._ind);
    }
  }
};
</script>

<style lang="scss" scoped>
.section2 {
  position: relative;
  height: 5.4rem;
  .title {
    position: relative;
    font-size: 0.18rem;
    line-height: 0.18rem;
    padding-top: 0.535rem;
    padding-bottom: 0.2rem;
    padding-left: 0.1rem;
    margin: 0 0.66rem;
    box-sizing: border-box;
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      height: 5.4rem;
      border-left: 1px solid #151515;
    }
    &::after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: -0.42rem;
      width: 8rem;
      border-bottom: 1px solid #151515;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }
  .fade-enter {
    opacity: 0;
    transform: translate(-50%, 0);
  }
  .fade-leave-to {
    opacity: 0;
    transform: translate(50%, 0);
  }
  .swipper {
    position: absolute;
    top: 1.295rem;
    left: 1rem;
    height: 4.1rem;
    width: 8.6rem;
    .swipper-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 7rem;
      height: 3.1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .img {
        flex: 0 0 auto;
        margin-right: 0.37rem;
        height: 70%;
        &:nth-child(2) {
          height: 100%;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .img3 {
        flex: 0 0 auto;
        margin-right: 0.3rem;
        height: 30%;
        &:nth-child(2) {
          height: 50%;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .menu {
      position: absolute;
      top: 0.4rem;
      right: 0;
      width: 1.6rem;
      height: 3.1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item {
        position: relative;
        width: 0.6rem;
        margin: 0 auto;
        margin-bottom: 0.39rem;
        text-align: left;
        font-size: 0.12rem;
        line-height: 0.15rem;
        color: #bcbcbc;
        transition: all 0.2s;
        cursor: pointer;
      }
      .item-active {
        font-size: 0.13rem;
        color: #fff;
        &::before {
          content: " ";
          position: absolute;
          top: 50%;
          left: -0.4rem;
          width: 0.2rem;
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
}
</style>
