var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section4"},[_c('div',{staticClass:"title1"},[_vm._v("我们的团队，太过专(chu)业(ming)")]),_c('div',{staticClass:"title2"},[_vm._v("以至于，不好意思(bu tai fang bian)露面。")]),_c('div',{staticClass:"swiper"},[_c('div',{staticClass:"tools"},[_c('div',{staticClass:"ind"},[_vm._v("0"+_vm._s(_vm.currentIndex + 1)+".")]),(_vm.currentIndex == 0)?_c('div',{staticClass:"title"},[_vm._v("公司形象墙")]):_vm._e(),(_vm.currentIndex == 1)?_c('div',{staticClass:"title"},[_vm._v("公司形象墙")]):_vm._e(),(_vm.currentIndex == 2)?_c('div',{staticClass:"title"},[_vm._v("休闲娱乐区")]):_vm._e(),(_vm.currentIndex == 3)?_c('div',{staticClass:"title"},[_vm._v("研发部")]):_vm._e(),(_vm.currentIndex == 4)?_c('div',{staticClass:"title"},[_vm._v("零食角(一)")]):_vm._e(),(_vm.currentIndex == 5)?_c('div',{staticClass:"title"},[_vm._v("阳光绿植")]):_vm._e(),(_vm.currentIndex == 6)?_c('div',{staticClass:"title"},[_vm._v("加班日常")]):_vm._e(),(_vm.currentIndex == 7)?_c('div',{staticClass:"title"},[_vm._v("会议日常")]):_vm._e(),_c('div',{staticClass:"arrows"},[_c('img',{staticClass:"arrow-item arrow-item-prev",attrs:{"src":require("./imgs/arrow.png")},on:{"click":_vm.prev}}),_c('img',{staticClass:"arrow-item",attrs:{"src":require("./imgs/arrow.png")},on:{"click":_vm.next}})])]),_c('div',{staticClass:"photos"},[_c('img',{staticClass:"photo",class:{
          'photo-show': _vm.currentIndex == 0,
          'photo-standby': _vm.currentIndex == 3
        },attrs:{"src":require("./imgs/section4-1.png")}}),_c('img',{staticClass:"photo",class:{
          'photo-show': _vm.currentIndex == 1,
          'photo-standby': _vm.currentIndex == 0
        },attrs:{"src":require("./imgs/section4-2.png")}}),_c('img',{staticClass:"photo",class:{
          'photo-show': _vm.currentIndex == 2,
          'photo-standby': _vm.currentIndex == 1
        },attrs:{"src":require("./imgs/section4-3.png")}}),_c('img',{staticClass:"photo",class:{
          'photo-show': _vm.currentIndex == 3,
          'photo-standby': _vm.currentIndex == 2
        },attrs:{"src":require("./imgs/section4-9.png")}}),_c('img',{staticClass:"photo",class:{
          'photo-show': _vm.currentIndex == 4,
          'photo-standby': _vm.currentIndex == 3
        },attrs:{"src":require("./imgs/section4-5.png")}}),_c('img',{staticClass:"photo",class:{
          'photo-show': _vm.currentIndex == 5,
          'photo-standby': _vm.currentIndex == 4
        },attrs:{"src":require("./imgs/section4-6.png")}}),_c('img',{staticClass:"photo",class:{
          'photo-show': _vm.currentIndex == 6,
          'photo-standby': _vm.currentIndex == 5
        },attrs:{"src":require("./imgs/section4-7.png")}}),_c('img',{staticClass:"photo",class:{
          'photo-show': _vm.currentIndex == 7,
          'photo-standby': _vm.currentIndex == 6
        },attrs:{"src":require("./imgs/section4-8.png")}})])]),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }