<template>
  <div class="home">
    <div class="section">
      <Section1></Section1>
      <ScrollTip></ScrollTip>
    </div>
    <div class="section">
      <Section2></Section2>
      <ScrollTip></ScrollTip>
    </div>
    <div class="section">
      <Section3></Section3>
      <ScrollTip></ScrollTip>
    </div>
    <div class="section">
      <Section4></Section4>
    </div>
  </div>
</template>

<script>
import ScrollTip from "../ScrollTip";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";

export default {
  name: "home",
  components: {
    Section1,
    ScrollTip,
    Section2,
    Section3,
    Section4
  },
  mounted() {
    const ua = navigator.userAgent;
    const ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
    const isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);
    const isAndroid = ua.match(/(Android)\s+([\d.]+)/);
    const isMobile = isIphone || isAndroid;
    if (!isMobile) {
      this._fullPage = new window.fullpage(this.$el, {
        anchors: ["section1", "section2", "section3", "section4", "section5"],
        scrollOverflow: true
      });
    }
  },
  beforeDestroy() {
    if (this._fullPage) {
      this._fullPage.destroy("all");
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  position: relative;
}
</style>
