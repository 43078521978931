<template>
  <div class="prodcenter">
    <Header></Header>
    <Section1></Section1>
    <Section2></Section2>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../Header";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Footer from "../Footer";

export default {
  components: {
    Header,
    Section1,
    Section2,
    Footer
  }
};
</script>

<style lang="scss" scoped>
.prodcenter {
  position: relative;
}
</style>
