<template>
  <div class="section1">
    <!-- <div @click="goAsk" class="btn"></div> -->
    <div class="box">
      <div class="title">Chat AI 中文版</div>
      <div class="tip">
        试着问问，举例：“10周年生日有什么创意？” ”用简单的术语解释量子计算“ →
      </div>
      <div class="tip2">“给我一份200字以内的关于红酒分类的市场调研报告“ →</div>
      <img class="img1" src="./imgs/session_img1.png" />
      <img class="img2" src="./imgs/session_img2.png" />
      <img class="img3" src="./imgs/session_img3.png" />
      <img class="img4" src="./imgs/session_img4.png" />
      <div class="chatbox">
        <iframe src="//openai.zhichenghz.com/" width="100%" height="100%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {}
};
</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  background: #000;
  .box {
    width: 100%;
    height: 4.5rem;
    background: url("./imgs/session_bg.png");

    // img:not([src]) {
    //   opacity: 0;
    // }

    .title {
      position: absolute;
      left: 0.1rem;
      top: 0.1rem;
      color: #b8ecff;
    }

    .tip {
      font-size: 0.12rem;
      text-align: center;
      color: #b8ecff;
      padding-top: 0.1rem;
    }

    .tip2 {
      font-size: 0.12rem;
      text-align: center;
      color: #b8ecff;
      padding-top: 0.1rem;
    }

    .img1 {
      position: absolute;
      // background: url("./imgs/session_img1.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      top: 1rem;
      width: 1.34rem;
      height: 1rem;
    }

    .img2 {
      position: absolute;
      // background: url("./imgs/session_img2.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      top: 0;
      right: 0;
      float: right;
      width: 1rem;
      height: 1rem;
    }

    .img3 {
      position: absolute;
      // background: url("./imgs/session_img3.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      bottom: 0;
      width: 0.75rem;
      height: 1rem;
    }

    .img4 {
      position: absolute;
      // background: url("./imgs/session_img4.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      bottom: 0.3rem;
      right: 0.3rem;
      float: right;
      width: 0.81rem;
      height: 1rem;
    }
    .chatbox {
      width: 72%;
      height: 3.5rem;
      padding: 0.1rem;
      left: 12%;
      position: absolute;
      top: 0.65rem;
      background: url("./imgs/session_border.png");
    }
  }
}
</style>
