<template>
  <div class="section3">
    <div class="title">我们的客户，全都比我们“知名”。</div>
    <div class="logos">
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/阿里云1.png" />
        <img class="logo-hover" src="./logo/阿里云.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/电信1.png" />
        <img class="logo-hover" src="./logo/电信.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/冻品云1.png" />
        <img class="logo-hover" src="./logo/冻品云.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/海尔1.png" />
        <img class="logo-hover" src="./logo/海尔.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/来一份1.png" />
        <img class="logo-hover" src="./logo/来一份.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/趴趴象1.png" />
        <img class="logo-hover" src="./logo/趴趴象.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/市民卡1.png" />
        <img class="logo-hover" src="./logo/市民卡.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/松下1.png" />
        <img class="logo-hover" src="./logo/松下.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/数联同创1.png" />
        <img class="logo-hover" src="./logo/数联同创.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/网易1.png" />
        <img class="logo-hover" src="./logo/网易.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/新华网1.png" />
        <img class="logo-hover" src="./logo/新华网.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/亚马逊1.png" />
        <img class="logo-hover" src="./logo/亚马逊.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/浙江日报1.png" />
        <img class="logo-hover" src="./logo/浙江日报.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/浙江卫视1.png" />
        <img class="logo-hover" src="./logo/浙江卫视.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/中国银行1.png" />
        <img class="logo-hover" src="./logo/中国银行.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/浙金1.png" />
        <img class="logo-hover" src="./logo/浙金.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/稻普1.png" />
        <img class="logo-hover" src="./logo/稻普.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/秀尔1.png" />
        <img class="logo-hover" src="./logo/秀尔.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/云信1.png" />
        <img class="logo-hover" src="./logo/云信.png" />
      </div>
      <div class="logo">
        <Corner />
        <img class="logo-normal" src="./logo/麦田1.png" />
        <img class="logo-hover" src="./logo/麦田.png" />
      </div>
    </div>
  </div>
</template>

<script>
import Corner from "./Corner";

export default {
  components: {
    Corner
  }
};
</script>

<style lang="scss" scoped>
.section3 {
  height: 5.4rem;
  background: url(./imgs/section3-bg.png) no-repeat;
  background-size: 100% 100%;
  .title {
    margin: 0.46rem 0.62rem 0 5.925rem;
    font-size: 0.18rem;
  }
  .logos {
    width: 7.52rem;
    margin: 0.5rem auto;
    height: 1rem;
  }
  .logo {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 1.015rem;
    height: 0.51rem;
    margin-right: 0.61rem;
    margin-bottom: 0.54rem;
    transition: all 0.3s;
    cursor: pointer;
    &:nth-child(5n + 5) {
      margin-right: 0;
    }
    .logo-normal,
    .logo-hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.3s;
    }
    .logo-normal {
      opacity: 1;
    }
    &:hover {
      background: #fff;
      .corner-box {
        transform: scale(1.2, 1.2);
      }
    }
    &:hover .logo-hover {
      opacity: 1;
    }
    &:hover .logo-normal {
      opacity: 0;
    }
  }
}
</style>
