<template>
  <div class="section3">
    <div class="title">
      免费获取报价方案<img src="./imgs/section3-icon.png" />
    </div>
    <div class="info">
      <span>姓名</span>
      <input type="text" />
      <span>手机号</span>
      <input type="phone" />
    </div>
    <div @click="goAsk" class="btn">立即获取</div>
  </div>
</template>

<script>
export default {
  methods: {
    goAsk() {
      window.open(
        // "https://affim.baidu.com/unique_46250393/chat?siteId=18921975&userId=46250393&siteToken=016057f948536c7916352fbab244ef24"
        "https://affim.baidu.com/unique_46250393/chat?siteId=18921975&userId=46250393&siteToken=016057f948536c7916352fbab244ef24"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.section3 {
  position: relative;
  height: 2rem;
  background: #f7fafb;
  .title {
    padding-top: 0.3rem;
    text-align: center;
    font-size: 0.21rem;
    color: #333333;
    img {
      width: 0.3rem;
      margin-left: 0.1rem;
    }
  }
  .info {
    margin: 0.2rem 3rem;
    color: #222;
    font-size: 0.12rem;
    input {
      margin-left: 0.1rem;
      border-radius: 4px;
      height: 0.18rem;
      line-height: 0.18rem;
      border: 1px solid #bbc3c9;
    }
    span {
      margin-left: 0.2rem;
    }
  }
  .btn {
    margin: 0.3rem 4rem;
    width: 1.5rem;
    height: 0.3rem;
    font-size: 0.12rem;
    background: #0068fa;
    border-radius: 4px;
    line-height: 0.3rem;
    text-align: center;
    cursor: pointer;
  }
}
</style>
