<template>
  <div class="section1">
    <div
      class="info"
      v-for="(item, index) in infolist"
      :key="index"
      @click="sel(index)"
    >
      <img :src="item.img" />
      <div>
        <span class="title">{{ item.title }}</span>
        <br /><br />
        <span class="subtitle">{{ item.subtitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import newsData1 from "../../assets/data/news1";
import newsData2 from "../../assets/data/news2";
import newsData3 from "../../assets/data/news3";
import newsData4 from "../../assets/data/news4";
import newsData5 from "../../assets/data/news5";
import newsData6 from "../../assets/data/news6";
import newsData7 from "../../assets/data/news7";
import newsData8 from "../../assets/data/news8";
import newsData9 from "../../assets/data/news9";
import newsData10 from "../../assets/data/news10";

export default {
  data() {
    return {
      infolist: [
        newsData1,
        newsData2,
        newsData3,
        newsData4,
        newsData5,
        newsData6,
        newsData7,
        newsData8,
        newsData9,
        newsData10
      ]
    };
  },
  methods: {
    sel(index) {
      this.$router.push("/infodetail/" + (index + 1));
    },
    loadData() {}
  },
  created() {
    this.loadData();
  }
};
</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  background: #fff;
  padding-bottom: 0.05rem;
  .info {
    color: #333;
    font-size: 0.1rem;
    display: flex;
    justify-content: flex-start;
    padding-top: 0.1rem;
    padding-left: 2rem;
    cursor: pointer;
    img {
      width: 1.2rem;
      height: 1.2rem;
      object-fit: cover;
      border-radius: 0.06rem;
    }
    div {
      position: relative;
      margin-top: 0.25rem;
      margin-left: 0.2rem;
      .title {
        font-size: 0.15rem;
        color: #333;
      }
      .subtitle {
        font-size: 0.1rem;
        color: #999;
      }
    }
  }
}
</style>
