<template>
  <div class="section2">
    <div class="title">IM即时通讯</div>
    <img class="img1" src="./imgs/section2-1.png" />
    <img class="img2" src="./imgs/section2-2.png" />
    <img class="img3" src="./imgs/section2-3.png" />
    <img class="img4" src="./imgs/section2-4.png" />
    <img class="img5" src="./imgs/section2-5.png" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section2 {
  position: relative;
  background: url(./imgs/section2-bg.png) no-repeat;
  background-size: 100% 100%;
  height: 5.4rem;
  padding: 1px 0;
  .title {
    margin-top: 0.36rem;
    margin-left: 7.49rem;
    font-size: 0.31rem;
    color: #fff;
  }
  // 要就留着不要删掉
  img {
    transition: all 0.3s;
    &:hover {
      cursor: pointer;
      transform: scale(1.12, 1.12);
    }
  }
  .img1,
  .img2,
  .img3,
  .img4,
  .img5 {
    position: absolute;
    width: 1.41rem;
    height: 3.05rem;
    top: 1.64rem;
  }
  .img1 {
    left: 0.885rem;
  }
  .img2 {
    left: 2.44rem;
  }
  .img3 {
    left: 3.985rem;
  }
  .img4 {
    left: 5.5rem;
  }
  .img5 {
    top: 2.63rem;
    left: 6.99rem;
    width: 2.26rem;
    height: 1.315rem;
    &:hover {
      transform: scale(1.6, 1.6);
    }
  }
}
</style>
