const newsData = {
  title: "我是标题1",
  subtitle: "我是副标题1",
  date: "" + new Date(),
  author: "Customer",
  keywords: "",
  img: require("./imgs/info1.png"),
  content: "我是内容1"
};

export default newsData;
