<template>
  <div class="section5">
    <div class="title">微信小程序</div>
    <img class="img1" src="./imgs/section5-01.png" />
    <img class="img2" src="./imgs/section5-02.png" />
    <img class="img3" src="./imgs/section5-03.png" />
    <img class="img4" src="./imgs/section5-04.png" />
    <img class="img5" src="./imgs/section5-05.png" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section5 {
  position: relative;
  background: #fff;
  height: 5.4rem;
  padding: 1px 0;
  .title {
    margin-top: 0.565rem;
    margin-left: 7.55rem;
    font-size: 0.31rem;
    color: #222;
  }

  .img1,
  .img2,
  .img3,
  .img4,
  .img5 {
    position: absolute;
    width: 1.6955rem;
    height: 3.32rem;
    top: 1.34rem;
    transition: all 0.3s;
    &:hover {
      cursor: pointer;
      transform: scale(1.2, 1.2);
    }
  }
  .img1 {
    left: 0.59rem;
  }
  .img2 {
    left: 2.24rem;
  }
  .img3 {
    left: 3.885rem;
  }
  .img4 {
    left: 5.53rem;
  }
  .img5 {
    left: 7.285rem;
  }
}
</style>
