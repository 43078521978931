<template>
  <div class="section4">
    <div class="title1">我们的团队，太过专(chu)业(ming)</div>
    <div class="title2">以至于，不好意思(bu tai fang bian)露面。</div>
    <div class="swiper">
      <div class="tools">
        <div class="ind">0{{ currentIndex + 1 }}.</div>
        <div class="title" v-if="currentIndex == 0">公司形象墙</div>
        <div class="title" v-if="currentIndex == 1">公司形象墙</div>
        <div class="title" v-if="currentIndex == 2">休闲娱乐区</div>
        <div class="title" v-if="currentIndex == 3">研发部</div>
        <div class="title" v-if="currentIndex == 4">零食角(一)</div>
        <div class="title" v-if="currentIndex == 5">阳光绿植</div>
        <div class="title" v-if="currentIndex == 6">加班日常</div>
        <div class="title" v-if="currentIndex == 7">会议日常</div>
        <!-- <div class="title" v-if="currentIndex == 8">知识产权</div> -->
        <div class="arrows">
          <img
            class="arrow-item arrow-item-prev"
            src="./imgs/arrow.png"
            @click="prev"
          />
          <img class="arrow-item" src="./imgs/arrow.png" @click="next" />
        </div>
      </div>
      <div class="photos">
        <img
          class="photo"
          :class="{
            'photo-show': currentIndex == 0,
            'photo-standby': currentIndex == 3
          }"
          src="./imgs/section4-1.png"
        />
        <img
          class="photo"
          :class="{
            'photo-show': currentIndex == 1,
            'photo-standby': currentIndex == 0
          }"
          src="./imgs/section4-2.png"
        />
        <img
          class="photo"
          :class="{
            'photo-show': currentIndex == 2,
            'photo-standby': currentIndex == 1
          }"
          src="./imgs/section4-3.png"
        />
        <img
          class="photo"
          :class="{
            'photo-show': currentIndex == 3,
            'photo-standby': currentIndex == 2
          }"
          src="./imgs/section4-9.png"
        />
        <img
          class="photo"
          :class="{
            'photo-show': currentIndex == 4,
            'photo-standby': currentIndex == 3
          }"
          src="./imgs/section4-5.png"
        />
        <img
          class="photo"
          :class="{
            'photo-show': currentIndex == 5,
            'photo-standby': currentIndex == 4
          }"
          src="./imgs/section4-6.png"
        />
        <img
          class="photo"
          :class="{
            'photo-show': currentIndex == 6,
            'photo-standby': currentIndex == 5
          }"
          src="./imgs/section4-7.png"
        />
        <img
          class="photo"
          :class="{
            'photo-show': currentIndex == 7,
            'photo-standby': currentIndex == 6
          }"
          src="./imgs/section4-8.png"
        />
        <!-- <img
          class="photo"
          :class="{
            'photo-show': currentIndex == 8,
            'photo-standby': currentIndex == 7
          }"
          src="./imgs/section4-4.png"
        /> -->
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../Footer";

export default {
  components: {
    Footer
  },
  data() {
    return {
      currentIndex: 0
    };
  },
  watch: {
    currentIndex() {
      if (this._ind) {
        clearTimeout(this._ind);
        this._ind = setTimeout(this.next, 5000);
      }
    }
  },
  methods: {
    next() {
      this.currentIndex++;
      if (this.currentIndex > 7) {
        this.currentIndex = 0;
      }
    },
    prev() {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = 7;
      }
    }
  },
  created() {
    this._ind = setTimeout(this.next, 5000);
  },
  beforeDestroy() {
    if (this._ind) {
      clearTimeout(this._ind);
    }
  }
};
</script>

<style lang="scss" scoped>
.section4 {
  height: 100%;
  .title1 {
    font-size: 0.18rem;
    margin-top: 0.28rem;
    margin-left: 0.65rem;
  }
  .title2 {
    margin-top: 0.25rem;
    margin-left: 2.48rem;
    font-size: 0.18rem;
  }
  .swiper {
    height: 3.4rem;
    .tools {
      .ind {
        margin-left: 0.545rem;
        margin-top: 0.735rem;
        font-size: 0.09rem;
      }
      .title {
        margin-top: 0.2rem;
        margin-left: 0.73rem;
        font-size: 0.15rem;
      }
    }
  }
  .arrows {
    position: absolute;
    z-index: 2;
    left: 1.13rem;
    top: 3.53rem;
    .arrow-item {
      display: inline-block;
      vertical-align: middle;
      width: 0.16rem;
      margin-right: 0.1rem;
      opacity: 0.5;
      transition: all 0.2s;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
    .arrow-item-prev {
      transform: rotate(180deg);
    }
  }
  .photos {
    position: absolute;
    top: 1.61rem;
    left: 2.77rem;
    right: 0;
    height: 2.85rem;
    overflow: hidden;
    .photo {
      position: absolute;
      top: 0;
      left: 0;
      width: 5.27rem;
      height: 2.85rem;
      opacity: 0;
      transition: all 0.5s;
      transform: scale(0.1, 0.1);
    }
    .photo-standby {
      position: absolute;
      top: 0;
      left: 5.835rem;
      height: 2.46rem;
      width: auto;
      opacity: 0.5;
      transform: scale(1, 1);
    }
    .photo-show {
      position: absolute;
      top: 0;
      left: 0;
      width: 5.27rem;
      height: 2.85rem;
      opacity: 1;
      transform: scale(1, 1);
    }
  }
}
</style>
