let deviceWidth = document.documentElement.clientWidth;
const calc = () => {
  deviceWidth = document.documentElement.clientWidth;
  document.documentElement.style.fontSize = deviceWidth / 9.6 + "px";
  document.documentElement.setAttribute("dpr", window.devicePixelRatio);
};

/**
 * 转换px为rem
 * @param {px} pxNum
 */
export const trans = function(px) {
  if (typeof px == "string" && px.indexOf("px") > -1) {
    px = parseFloat(px.replace("px", ""));
    return (9.6 * px) / deviceWidth + "rem";
  }
  return (9.6 * px) / deviceWidth;
};

/**
 * 初始化rem,支持传入指定屏幕宽度
 * @param {*} width
 */
export default function(width) {
  deviceWidth = width || document.documentElement.clientWidth;
  window.addEventListener("resize", calc);
  calc();
}
